/* Main Layout */
.whatsapp-manager {
    display: flex;
    height: 100vh;
}

.main-content {
    flex: 1;
    padding: 20px;
    background-color: #f5f7fb;
}

/* Sidebar */
.sidebar {
    width: 250px;
    background-color: #ffffff;
    padding: 20px;
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar button {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    background-color: #ffffff;
    border: 2px solid #31c76a;
    color: #31c76a;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sidebar button:hover {
    background-color: #31c76a;
    color: #ffffff;
}

.sidebar img {
    margin-top: 20px;
    max-width: 100%;
}

/* Top Bar */
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.top-bar select,
.top-bar input {
    padding: 10px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-size: 16px;
}

.top-bar input {
    width: 300px;
}

.top-bar button {
    padding: 10px 20px;
    background-color: #4876ea;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.top-bar button:hover {
    background-color: #3653bf;
}

/* Templates Table */
table {
    width: 100%;
    background-color: #ffffff;
    border-collapse: collapse;
    border-radius: 10px;
    /* overflow: hidden; */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

thead {
    background-color: #f7f7f7;
}

thead th {
    padding: 15px;
    text-align: left;
    font-weight: bold;
    color: #555555;
}

tbody tr {
    border-bottom: 1px solid #eaeaea;
}

tbody tr:hover {
    background-color: #f1f1f1;
}

tbody td {
    padding: 15px;
    color: #666666;
}

tbody td:last-child {
    text-align: right;
}

/* Individual Row */
tr {
    transition: background-color 0.3s ease;
}

tr:hover {
    background-color: #f9f9f9;
}

/* Button */
button {
    padding: 10px 20px;
    background-color: #4876ea;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #3653bf;
}

/* Dropdown */
select {
    padding: 10px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-size: 16px;
}

/* Search */
input[type="text"] {
    padding: 10px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-size: 16px;
    /* width: 300px; */
}
